export function vues(){
	// USE VUE
    let faq = {
		data: function () {
			return {
				flag: false
			}
		},
		methods: {
			toggle : function(){
				this.flag = !this.flag;
			}
		},
		props: ['question' , 'answer'],
		template: 
		`<dl :class="{ active: flag }">
            <dt @click="toggle">{{ question }}<i class="fas fa-angle-down"></i></dt>
            <dd v-show="flag" v-html="answer"></dd>
        </dl>`
	};
		
	const wrap_vue = new Vue({
		el: '#app',
		data: {
            sitename: 'Dogas',
            privacy_toggle: false,
			contact_flag: false,
			contact_name: '',
			contact_email: '',
            works: 'all'
        },
		methods: {
			validate: function(){
				if(this.contact_email.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/) && this.contact_name != ''){
					this.contact_flag = false;
				}else{
					this.contact_flag = true;
				}
			}
		},
		components: {
			'faq' : faq,
		}
	});
}